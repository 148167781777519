@keyframes showSmooth {
  from {
    opacity: 0;
  }to {
    opacity: 1;
  }
}

.dash-panels {
  width: 100%;
  border-radius: 10px;
}

.filter-header {
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
}

.filter-header-title {
  font-size: 20px;
  font-weight: bold;
}

.processSummary {
  display: grid;
  grid-template-columns: repeat(4, auto);
  column-gap: 10px;
  margin-top: 20px;
  height: 130px;
}

.simple-card {
  padding: 20px;
  background: #fff;
  border-radius: 10px;
  color: #fff;
  box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}

.simple-card>.t {
  font-size: 18px;
  font-weight: 600;
}

.simple-card>.v {
  font-size: 25px;
  font-weight: bold;
  text-align: right;
}

.process-Summary-charts {
  margin-top: 20px;
}
* {
  box-sizing: border-box;
}

body {
  margin: 0;
}

.theme-dark {
  --dark-text: #ff7b00;
  --light-text: #F9F8F8;
  --dark-background: #343a47;
  --light-background: #001529;
  --accent: #B8DBD9;
  --button-border: #B8DBD9;
}

.theme-light {
  --dark-text: #204080;
  --light-text: #333333;
  --dark-background: #f8f8f8;
  --light-background: #ffffff;
  --accent: #DBE7E4;
  --button-border: #5E4B56;
}

/* width */
::-webkit-scrollbar {
  width: 15px;
  height: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

.main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
}

.header {
  /* border-bottom: 1px solid #f2f2f2; */
}

.content {
  height: 100%;
  width: 100%;
}

.content-sidebar-view {
  display: flex;
  flex-direction: row;
  margin-top: 50px;
  position: relative;
}

.side-navigation-container {
  height: 100%;
  display: flex;
  flex-direction: column;
  width: 256px;
  overflow: hidden;
}

.nav-toggle-button {
  width: 100%;
  display: flex;
  background-color: #1890ff;
  transition: .3s;
  align-self: flex-end;
}

.side-navigation {
  height: calc(100vh - 50px);
  position: sticky;
  position: -webkit-sticky;
  position: sticky;
  top: 50px;
}

.main-content {
  width: 100%;
  height: 100%;
  background-color: var(--dark-background);
}

.container-mid {
  padding: 20px 20px;
  max-width: 1500px;
  margin: 0 auto;
  position: relative;
  background-color: var(--light-background);
}

.real-mid-container {
  width: 100%;
  height: calc(100vh - 80px);
}

.header-center {
  position: sticky;
  width: 100%;
  height: 50px;
  top: 0;
  z-index: 30;
  height: 50px;
  display: flex;
  align-items: center;
  margin-top: -70px;
  background-color: var(--light-background);
  color: var(--dark-text);
  width: fit-content;
}

.breadcrumb-index {
  font-size: 24px;
  font-weight: bold;
  color: var(--dark-text);
  cursor: pointer;
}

.breadcrumb-main {
  font-size: 20px;
  font-weight: bold;
  color: var(--dark-text);
  cursor: pointer;
}

.breadcrumb-text {
  color: var(--light-text);
}

.bread-separator {
  font-weight: 900;
  margin-right: 5px;
  color: var(--light-text);
}

.page-content {
  margin-top: 20px;
  min-height: calc(100vh - 90px);
}

.active-state {
  color: #1890ff
}

.cancelled-state {
  color: orange
}

.finished-state {
  color: green;
}

.expired-state {
  color: red;
}

.loading-main {
  width: 100%;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.sms-grafic-container {
  margin-bottom: 10px;
  border-radius: 10px;
  width: 100%;
  height: 300px;
  overflow: hidden;
}

.sms-grafic {
  border-radius: 10px;
  width: 100%;
  height: 100%;
  background-color: #fff;
}
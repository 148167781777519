.container{
  margin:0 20%
}
.userTable{
  margin-top: 30px;
}
.ant-btn-warning{
  color: rgb(241, 186, 3); 
  border-color: rgb(241, 186, 3);
}
.ant-btn-warning:hover{
  color: rgb(240, 194, 46); 
    border-color: rgb(241, 186, 3);
}
.actions-container{
  display: flex;
  justify-content:center;
}
.header {
  height: 50px;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 0px 20px;
  padding-left: 15px;
  position: relative;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 21;
  background-color: var(--light-background);
  color: var(--dark-text);
  box-shadow: 0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24);
}

.header-title {
  font-size: 20px;
}

.header-left {
  flex: 1;
  display: flex;
}

.side-toggle {
  color: var(--dark-text) !important;
  border-color: var(--dark-text) !important;
}

.toggle-theme {
  margin-right: 20px;
  display: flex;
  align-items: center;
}

.toggle-theme > button > div {
  margin-top: -32.5px !important;
}

.toggle-theme > button {
  border: none !important;
}

.header-right {
  display: flex;
}

.avatar {
  cursor: pointer;
  z-index: 21;
}

.user-avatar {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.avatar-username {
  font-weight: 600;
  font-size: 13px;
  margin-right: 10px;
  user-select: none;
}
.signers-table-container {
  margin-top: 30px;
}


.process-stats-chart {
  border-radius: 10px;
}

.process-info {
  display: flex;
  flex-direction: column;
}

.process-info-label {
  font-weight: 900;
}

.signers-table-title {
  flex: auto;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
  font-size: 16px;
  line-height: 1.5715;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-bottom: 20px;
}
.integration-list-page {
  position: relative;
}

.table-fake-header {
  position: sticky;
  top: 0;
  margin-top: -70px;
  height: 50px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 22;
}

.disable {
  color: red;
  font-weight: bold;
}

.active {
  color: green;
  font-weight: bold;
}
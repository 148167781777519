
.splash-view {
  width: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.logo {
  width: 40%;
  min-width: 300px;
}

.app-title {
  margin-top: 40px;
  font-size: 3vw;
  font-weight: 900;
  color: #204080;
}
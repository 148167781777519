
.table-container {
  height: 100%;
}

.table-title {
  flex: 1;
  font-weight: bold;
}

.table-header {
  display: flex;
  align-items: center;
  padding: 10px 0px;
  justify-content:flex-end;
  position: sticky;
  top: 50px;
  z-index: 20;
  background-color: var(--light-background);
  color: var(--light-text);
}

.table-tools > button {
  margin-left: 10px;
}

.table-filters {
  flex: 1;
  display: flex;
}

.filter:not(:last-child) {
  margin-right: 10px;
}

.range-picker-container {
  margin-bottom: 10px;
}

.select-picker-container {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
}

.select-checkbox {
  padding: 10px 5px;
}

.select-checkbox {
  border-bottom: 1px solid #f2f2f2;
}
.integration-container {
}

.integration-stats-chart {
  border-radius: 10px;
}

.integration-info {
  display: flex;
  flex-direction: column;
}

.integration-info-label {
  font-weight: 900;
}